import "./App.css";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import BugsImage from "./assets/bugs.jpg";
import ImageLogoWhite from "./assets/logo-horizontal-white.svg";
import IconWA from "./assets/wa.svg";
import IconInst from "./assets/inst.svg";

import ImageBack1 from "./assets/back1.jpg";
import ImageBack2 from "./assets/back2.jpg";
import ImageBack3 from "./assets/back3.jpg";
import ImageBack4 from "./assets/back4.jpg";
import ImageBack5 from "./assets/back5.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={ImageLogoWhite} height={80} />
        <div className="contacts">
          <a href="tel:+77715684050">+7 (771) 568 4050</a>
          <a href="https://wa.me/77715684050" target="_blank">
            <img src={IconWA} width={40} />
          </a>
          <a href="https://www.instagram.com/anngineer_kst/" target="_blank">
            <img src={IconInst} width={40} />
          </a>
        </div>
      </header>

      <div className="content">
        <div className="divider" />
        <section className="carousel">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showIndicators={false}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            interval={5000}
          >
            <div className="car-item">
              <img src={ImageBack1} />
              <p className="legend2">
                Проектное агентство "Перспектива" предлагает полный спектр услуг
                по разработке проектно-сметной документации от получения
                исходных данных до оформления акта ввода в эксплуатацию.
              </p>
            </div>
            <div className="car-item">
              <img src={ImageBack2} />
              <p className="legend2">
                Мы специализируемся на эскизном, техническом и рабочем
                проектировании, гарантируя индивидуальный подход к каждому
                клиенту.
              </p>
            </div>
            <div className="car-item">
              <img src={ImageBack5} />
              <p className="legend2">
                Наша команда специалистов обеспечит высокое качество выполнения
                проектов и соблюдение всех актуальных норм и стандартов.
              </p>
            </div>
          </Carousel>
        </section>
        <div className="divider" />
        <section
          style={{ marginBottom: 20, paddingLeft: 20, paddingRight: 20 }}
        >
          <h2>Наши услуги</h2>
          <div style={{ marginBottom: 50 }}>
            <ul style={{ paddingLeft: 15 }} className="services-list">
              <li>Эскизный проект (новое строительство)</li>
              <li>
                Технический проект (перепланировка, реконструкция, капитальный
                ремонт)
              </li>
              <li>
                Рабочий проект (генплан, архитектурно-строительная часть,
                инженерные сети, ПОС, экологический раздел, смета)
              </li>
              <li>Самостоятельный и комиссионный акты ввода в эксплуатацию</li>
              <li>Авторский надзор</li>
              <li>Бесплатная консультация по вопросам проектирования</li>
            </ul>
          </div>

          <div style={{ marginBottom: 50 }}>
            <button
              className="cta-btn"
              onClick={() => {
                window.location.href = "https://wa.me/77715684050";
              }}
            >
              Cвяжитесь с нами в WhatsApp
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              marginTop: 20,
            }}
          >
            <h2
              style={{
                textAlign: "center",
              }}
            >
              Наши Проекты
            </h2>
            <img src={BugsImage} width="300" />
          </div> */}
        </section>
        <div className="divider" />
      </div>
      <footer>
        <div className="footer-data">
          <h3>Наш Адрес: </h3>
          <h4>Байтурсынова 72, каб. 406А</h4>
          <div className="contacts">
            <a href="tel:+77715684050">+7 (771) 568 4050</a>
            <a href="https://wa.me/77715684050" target="_blank">
              <img src={IconWA} width={40} />
            </a>
            <a href="https://www.instagram.com/anngineer_kst/" target="_blank">
              <img src={IconInst} width={40} />
            </a>
          </div>
        </div>
        <div className="footer-map">
          <div style={{ position: "relative", overflow: "hidden" }}>
            <iframe
              src="https://yandex.kz/map-widget/v1/?ll=63.634948%2C53.216859&mode=whatshere&whatshere%5Bpoint%5D=63.634959%2C53.216838&whatshere%5Bzoom%5D=17&z=17.36"
              width="100%"
              height="295px"
              frameborder="0"
              allowfullscreen="true"
              style={{ position: "relative" }}
            ></iframe>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
